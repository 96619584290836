
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import LanguageSwitcher from '@/components/LanguageSwitcher.vue';

export default {
    components: {
        LanguageSwitcher
    },
    setup() {
        const passwordFieldType = ref('password');
        const Username = ref('');
        const Password = ref('');
        const forgotEmail = ref('');
        const forgotPassword = ref(false);
        const { t } = useI18n();
        async function login() {
            swal.showLoading();
            if (!Username.value || !Password.value) {
                swal.fire({
                    icon: 'error',
                    text: 'Must enter a username and password!'
                });
                return;
            }

            const response = await api.login(Username.value, Password.value);
            if (response.errorMessage) {
                swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: response.errorMessage });
                return;
            }
            window.location.assign('');
        }

        const resetPassword = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
            } else {
                const forgotPasswordResponse = await api.forgotPassword(forgotEmail.value);
                if (forgotPasswordResponse.data == false) {
                    swal.fire({ icon: 'error', text: t('forgot-password.warning.email-doesnt-exist') });
                    return;
                }
                if (forgotPasswordResponse.errorMessage) {
                    swal.fire({ icon: 'error', title: t('error-pop-up.oops'), text: forgotPasswordResponse.errorMessage });
                    return;
                }
                if (forgotPasswordResponse.data) {
                    forgotPassword.value = false;
                    forgotEmail.value = '';
                    swal.fire({
                        icon: 'success',
                        title: t('registration.wellDone'),
                        text: t('registration.weSentYouResetPasswordEmail')
                    });
                }
            }
        };

        return {
            Username,
            Password,
            login,
            forgotPassword,
            forgotEmail,
            resetPassword,
            passwordFieldType
        };
    }
};
