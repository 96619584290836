
import { generalStore } from '@/store';
import { useI18n } from 'vue-i18n';
import { api } from '@/services/Api';
import { computed, ref } from 'vue';

export default {
    async setup() {
        const { locale } = useI18n();

        const openedLocaleBlock = ref(false);

        const currentLanguageImage = computed(() => require(`@/assets/icons/${locale.value}.png`));

        const body = document.querySelector('body');
        const html = document.querySelector('html');
        const changeLang = async (lang: string): Promise<void> => {
            if (lang != locale.value) {
                await api.changeLanguage(lang);
                if (html && body) {
                    const dir = lang === 'he-IL' ? 'rtl' : 'ltr';
                    body.setAttribute('dir', dir);
                    html.setAttribute('lang', lang);
                    window.location.reload();
                    if (!body.classList.contains(dir)) {
                        body.classList.add(dir);
                        const removeClass = lang === 'he-IL' ? 'ltr' : 'rtl';
                        body.classList.remove(removeClass);
                    }
                }
                generalStore.commit('updateLang', lang);
                locale.value = generalStore.getters.currentLanguage;
            }
            openedLocaleBlock.value = false;
        };

        return {
            changeLang,
            openedLocaleBlock,
            locale,
            currentLanguageImage
        };
    }
};
